import mixinSecurity from '@/mixins/security/security'

export default {
  mixins: [
    mixinSecurity,
  ],
  methods: {
    getFinanceRoute () {
      if (this.$store.getters['features/isFeatureAvailableInPlan']('invoice_taxes')) {
        if (mixinSecurity.methods.hasPermission('read_invoice_transaction', this.$store.state.user)) {
          return 'invoices'
        }

        if (mixinSecurity.methods.hasPermission('read_tax', this.$store.state.user)) {
          return 'taxes'
        }
      }

      if (mixinSecurity.methods.hasPermission('read_invoice_transaction', this.$store.state.user)) {
        return 'transactions'
      }

      if (mixinSecurity.methods.hasPermission('read_employee_commission', this.$store.state.user)
        && !this.$store.getters['features/isFeatureEnabled']('coupons')
      ) {
        return 'commissions'
      }

      return 'coupons'
    },
  }
}
