<template>
  <div
    class="adm-avatar"
    :class="[`size-${ size }`, `margin-right-${ marginRight }`, `margin-left-${ marginLeft }`]"
    :style="getStyle()"
  >
    <template v-if="!src">
      {{ forcedName ? forcedName : getInitials(name) }}
    </template>
  </div>
</template>

<script>
import StringUtils from '@/utils/string'
import mixinColor from '@/mixins/common/color'

const margins = [-10, -5, 0, 4, 5, 8, 10, 12, 15, 16, 28]

export default {
  name: 'AdmAvatar',

  mixins: [
    mixinColor
  ],

  props: {
    size: {
      type: Number,
      default: 34,
      validator: function (value) {
        return [24, 28, 32, 34, 36, 40, 48, 54, 56, 60, 64, 80].indexOf(value) !== -1 || !value
      }
    },
    marginRight: {
      type: Number,
      default: 0,
      validator: function (value) {
        return margins.indexOf(value) !== -1 || !value
      }
    },
    marginLeft: {
      type: Number,
      default: 0,
      validator: function (value) {
        return margins.indexOf(value) !== -1 || !value
      }
    },
    name: {
      type: String,
      default: ''
    },
    forcedName: {
      type: String,
      default: null
    },
    isHuman: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      default: null
    },
    backgroundColor: {
      type: String,
      default: null
    },
    border: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: ''
    }
  },

  methods: {
    getStyle () {
      return {
        backgroundColor: this.src ? null : this.getBackgroundColor(),
        backgroundImage: this.src ? `url(${this.src})` : null,
        border: this.border,
        color: this.getFontColor()
      }
    },

    getInitials (name) {
      return StringUtils.getAcronym(name, this.isHuman)
    },

    getBackgroundColor () {
      if (this.backgroundColor) {
        return this.backgroundColor
      }

      return this.calculateBackgroundColorByNameLength()
    },

    getFontColor () {
      if (this.color) {
        return this.color
      }

      return this.lightenDarkenColor(this.getBackgroundColor(), -80)
    },

    calculateBackgroundColorByNameLength () {
      return this.avatarBackgroundColors[this.name.length % this.avatarBackgroundColors.length]
    }
  }
}
</script>

<style scoped lang="scss">
// Params in px
$sizes: 24, 28, 32, 34, 36, 40, 48, 54, 56, 60, 64, 80;
$margins: -10, -8, -5, 4, 5, 8, 10, 12, 15, 16, 28;

.adm-avatar {
  box-sizing: content-box;
  display: inline-flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: $adm-white;
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  background-position: 50% 50%;
  background-size: cover;

  @each $size in $sizes {
    &.size-#{$size} {
      height: #{$size}px;
      width: #{$size}px;
      font-size: #{ calc($size / 2.6) }px;
    }
  }

  @each $margin in $margins {
    &.margin-right-#{$margin} {
      margin-right: #{$margin}px;
    }

    &.margin-left-#{$margin} {
      margin-left: #{$margin}px;
    }
  }
}
</style>
